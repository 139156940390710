var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hg-100-percent" },
    [
      _c(
        "glb-spliter",
        {
          attrs: {
            gutter: 10,
            spanList: [
              { span: 5, slot: "left" },
              { span: 19, slot: "right" },
            ],
          },
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "main-left-tree-wrapper" }, [
              _c("div", { staticClass: "main-left-tree-header" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("lang_application_management"))),
                ]),
                _c("i", {
                  staticClass: "el-icon-plus",
                  attrs: { title: _vm.$t("lang_new_application_type") },
                  on: {
                    click: function ($event) {
                      return _vm.addAppType()
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "main-left-tree-content" },
                [
                  _c("el-input", {
                    staticClass: "main-left-tree-filter mgt-10",
                    attrs: {
                      placeholder: _vm.$t("lang_enter_keywords_to_filter"),
                      clearable: "",
                    },
                    on: { clear: _vm.getAppTypeTreeData },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getAppTypeTreeData($event)
                      },
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _c("el-tree", {
                    ref: "appTreeRef",
                    staticClass: "main-left-tree-instance",
                    attrs: {
                      data: _vm.appData,
                      props: _vm.appTreeProp,
                      "default-expand-all": "",
                      "expand-on-click-node": false,
                      "highlight-current": "",
                      "node-key": "id",
                    },
                    on: { "node-click": _vm.clickTreeNode },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "span",
                              { staticClass: "custom-tree-node tree-span" },
                              [
                                _c("span", [_vm._v(_vm._s(data.name))]),
                                data.id != -1
                                  ? _c(
                                      "span",
                                      [
                                        _c("el-button", {
                                          staticClass: "custom-tree-node-btn",
                                          attrs: {
                                            type: "text",
                                            size: "mini",
                                            icon: "el-icon-edit",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editAppType(data)
                                            },
                                          },
                                        }),
                                        _c("el-button", {
                                          staticClass: "custom-tree-node-btn",
                                          staticStyle: { color: "red" },
                                          attrs: {
                                            type: "text",
                                            icon: "el-icon-delete",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.removeAppType(data)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "el-card",
                { staticClass: "mgb-10 custom-card" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.formSearchModel,
                        inline: "",
                        "label-suffix": ": ",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mgb-0",
                          attrs: { label: _vm.$t("lang_apply_name") },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleSearch($event)
                              },
                            },
                            model: {
                              value: _vm.formSearchModel.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formSearchModel, "name", $$v)
                              },
                              expression: "formSearchModel.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "mgb-0" },
                        [
                          _c("el-button", {
                            attrs: { type: "primary", icon: "el-icon-search" },
                            on: { click: _vm.handleSearch },
                          }),
                          _c("el-button", {
                            attrs: { type: "primary", icon: "el-icon-refresh" },
                            on: { click: _vm.handleSearchReset },
                          }),
                          _c("el-button", {
                            attrs: { type: "primary", icon: "el-icon-plus" },
                            on: {
                              click: function ($event) {
                                return _vm.addAppForm()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { staticStyle: { height: "calc(100% - 72px)" } },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        data: _vm.formList,
                        height: "calc(100% - 40px)",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_serial_number"),
                          align: "center",
                          type: "index",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_name"),
                          align: "center",
                          prop: "displayName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_describe"),
                          align: "center",
                          prop: "displayDescription",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_state"),
                          align: "center",
                          prop: "status",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == 0
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "primary" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("lang_temporary_storage")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.status == 1
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v(_vm._s(_vm.$t("lang_published")))]
                                    )
                                  : _vm._e(),
                                scope.row.status == 2
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v(_vm._s(_vm.$t("lang_closed")))]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("lang_operation"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._clientWidth >= 1600
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#007aff" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editForm(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lang_edit")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#007aff" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.appConfigScope(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lang_configuration_range"
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#007aff" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.enterApp(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lang_enter_application")
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#007aff" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showCopyApp(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lang_copy_application")
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "red" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeApp(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lang_delete")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _c("glb-operate-button", {
                                      attrs: {
                                        "menu-list": [
                                          {
                                            label: _vm.$t("lang_edit"),
                                            method: _vm.editForm,
                                            arguments: scope.row,
                                          },
                                          {
                                            label: _vm.$t(
                                              "lang_configuration_range"
                                            ),
                                            method: _vm.appConfigScope,
                                            arguments: scope.row,
                                          },
                                          {
                                            label: _vm.$t(
                                              "lang_enter_application"
                                            ),
                                            method: _vm.enterApp,
                                            arguments: scope.row,
                                          },
                                          {
                                            label: _vm.$t(
                                              "lang_copy_application"
                                            ),
                                            method: _vm.showCopyApp,
                                            arguments: scope.row,
                                          },
                                          {
                                            label: _vm.$t("lang_delete"),
                                            method: _vm.removeApp,
                                            arguments: scope.row,
                                          },
                                        ],
                                      },
                                    }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.formPage.pageNo,
                      "page-sizes": [10, 15, 20, 50],
                      "page-size": _vm.formPage.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.formPage.total,
                    },
                    on: {
                      "size-change": _vm.changePageSize,
                      "current-change": _vm.changePageNo,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("app-type-form", {
        attrs: {
          visible: _vm.appTypeDiglogVisible,
          "app-type-id": _vm.curAppTypeId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.appTypeDiglogVisible = $event
          },
          close: _vm.closeAppTypeForm,
        },
      }),
      _c("app-form", {
        attrs: {
          visible: _vm.appDiglogVisible,
          "app-id": _vm.curAppId,
          "app-type-id": _vm.curAppTypeId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.appDiglogVisible = $event
          },
          close: _vm.getAppListByAppTypeId,
        },
      }),
      _c("app-config-scope", {
        attrs: {
          visible: _vm.appConfigScopeDiglogVisible,
          "app-id": _vm.curAppId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.appConfigScopeDiglogVisible = $event
          },
          close: _vm.getAppListByAppTypeId,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.appTypeTreeVisibal,
            width: "70%",
            "close-on-click-modal": false,
            "destroy-on-close": true,
            center: true,
            title: _vm.$t("lang_select_application_type"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.appTypeTreeVisibal = $event
            },
          },
        },
        [
          _c("app-type-tree", {
            attrs: { change: "" },
            model: {
              value: _vm.copyAppTypeId,
              callback: function ($$v) {
                _vm.copyAppTypeId = $$v
              },
              expression: "copyAppTypeId",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.copyApp()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_save_")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.appTypeTreeVisibal = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }